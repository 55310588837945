import * as React from "react"
import { Heading, Spacer, Text, useTheme } from "gatsby-interface"
import {
  sectionCss,
  sectionTitleCss,
  eyebrowHeadingCss,
  maxSectionWidth,
} from "../style-utils"
import { ColorSchemeCss, ColorSchemeTheme } from "../color-schemes"
import MarketingForm from "../../marketing-forms/MarketingForm"
import { AnchorSection } from "./AnchorSection"
import { getSSLPSanitizedHtml } from "../utils"

const eyebrowCss: ColorSchemeCss = theme => [
  eyebrowHeadingCss(theme),
  {
    marginBottom: 0,
    textAlign: "center",
  },
]

const containerCss: ColorSchemeCss = theme => ({
  alignItems: `center`,
  background: theme.colorScheme.muted,
  display: `flex`,
  flexDirection: `column`,
  padding: `${theme.space[13]} ${theme.space[7]}`,
  textAlign: `center`,
  position: `relative`,
  zIndex: 0,

  [theme.mediaQueries.desktop]: {
    paddingBottom: theme.space[15],
    borderRadius: theme.radii[4],
  },
})

const subtitleCss: ColorSchemeCss = theme => ({
  // need the specifity to override the default styles (ampersand doesn't do)
  // for GatsbyConf we need h2 and h3 currently - def. seeing global styles for those
  // this should cover most of things
  // wondering about hrefs
  // hard to test
  "h1, h2, h3, h4, h5, h6, p, ul, ol, dl": {
    color: theme.colorScheme.text,
  },
  fontSize: theme.fontSizes[3],
  marginTop: theme.space[4],
  maxWidth: maxSectionWidth.default,
  textAlign: `center`,
})
interface MarketoFormCampaignObject {
  formId: string
  name: string
}

interface MarketoThankYouMessage {
  childMarkdownRemark: {
    html: string
  }
}

interface MarketoFormData {
  id: string
  marketoFormCampaignObject: MarketoFormCampaignObject
  thankYouMessage: MarketoThankYouMessage
}

export interface ContactFormProps {
  eyebrow: string
  heading: string
  lede?: string
  form: MarketoFormData
}

function ContactForm({
  eyebrow,
  heading,
  lede,
  form,
}: ContactFormProps): JSX.Element {
  const theme = useTheme() as ColorSchemeTheme
  return (
    <AnchorSection id="contact" css={sectionCss}>
      <div css={containerCss}>
        {eyebrow ? <Text css={eyebrowCss}>{eyebrow}</Text> : null}
        {heading ? (
          <React.Fragment>
            <Heading
              as="h2"
              css={sectionTitleCss(theme)}
              dangerouslySetInnerHTML={{
                __html: getSSLPSanitizedHtml(heading),
              }}
            />
          </React.Fragment>
        ) : null}
        {lede ? (
          <React.Fragment>
            <Text
              as="div"
              css={subtitleCss}
              dangerouslySetInnerHTML={{ __html: getSSLPSanitizedHtml(lede) }}
            ></Text>
            <Spacer size={12} />
          </React.Fragment>
        ) : null}
        {form ? (
          <MarketingForm
            wrapperCss={{
              alignItems: `center`,

              form: {
                fontFamily: theme.fonts.body,
              },

              // The more specific rule including `.mktoForm` is one way
              // to properly override the `MarketoForm` `label` color.
              //
              // TODO haven't looked as deeply as to understand where the styles
              // are coming from
              //
              // Because `MarketingForm` either renders `MarketoForm`
              // (AFAIU the one we're using primarily, currently, for new stuff)
              // or `HubspotForm` (older implementation, legacy) we now have two
              // selectors defined for this rule.
              "& label, & .mktoForm label": {
                color: theme.colorScheme.text,
              },
            }}
            form={form}
          />
        ) : null}
      </div>
    </AnchorSection>
  )
}

export default ContactForm
