import * as React from "react"
import { getCtas } from "../utils"
import { getHtml, getImage } from "../utils"
import {
  ContentfulSSLPImage,
  ContentfulSelfServicePageContent,
  ContentfulSelfServicePageSection,
  Alignment,
} from "../types"
import FeatureColumn, { FeatureColumnProps } from "../components/FeatureColumn"

function ContentfulFeatureColumn(section: ContentfulSelfServicePageSection) {
  const props = mapProps(section)

  return <FeatureColumn {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): FeatureColumnProps {
  // Pull the Content and Appearance blocks out of the Section block that is passed in
  const { content, alignment = Alignment.Left } = entry

  // Normalize the data
  const featureColumnContent =
    content || ({} as ContentfulSelfServicePageContent)
  const icon = getImage(featureColumnContent?.icon)
  const [primaryCta, secondaryCta] = getCtas(featureColumnContent)

  const image = featureColumnContent?.images
    ? featureColumnContent.images[0]
    : ({} as ContentfulSSLPImage)
  // Return the props that will be passed to FeatureColumn
  return {
    icon,
    image: image?.constrained || getImage(image),
    title: featureColumnContent?.primaryText,
    eyebrow: featureColumnContent?.secondaryText || "",
    description: getHtml(featureColumnContent?.description),
    alignment,
    primaryCta,
    secondaryCta,
  }
}

export default ContentfulFeatureColumn
