import * as React from "react"
import { ContentfulSelfServicePageSection } from "../types"
import { getCtas } from "../utils"
import Subnavigation, { SubnavigationProps } from "../components/Subnavigation"

const ContentfulSubnavigation = (section: ContentfulSelfServicePageSection) => {
  const props = mapProps(section)
  return <Subnavigation {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): SubnavigationProps {
  // Pull the Content, Items, and Appearance blocks out of the Section block that is passed in
  const { content } = entry

  // Normalize the data
  const ctas = getCtas(content)

  // Return the props that will be passed to FeatureGrid
  return { ctas }
}

export default ContentfulSubnavigation
