import * as React from "react"
import {
  ContentfulSelfServicePageContent,
  ContentfulSelfServicePageSection,
} from "../types"
import { getHtml } from "../utils"
import WalkThrough, {
  WalkThroughProps,
  WalkThroughItem,
} from "../components/WalkThrough"

const ContentfulWalkThrough = (section: ContentfulSelfServicePageSection) => {
  const props = mapProps(section)
  return <WalkThrough {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): WalkThroughProps {
  // Pull the Content, Items, and Appearance blocks out of the Section block that is passed in
  const { content, items: itemList = [] } = entry

  // Normalize the data
  const walkThoughContent = content
  const items = itemList.map(
    (item: ContentfulSelfServicePageContent): WalkThroughItem => ({
      text: getHtml(item.description),
      time: item.secondaryText,
    })
  )

  const initialStepNumber = items.length !== 0 ? +itemList[0].primaryText : 1

  // Return props that will be passed to WalkThrough
  return {
    heading: walkThoughContent?.primaryText,
    lede: walkThoughContent?.secondaryText,
    items,
    initial: initialStepNumber,
    callout: getHtml(walkThoughContent?.description),
  }
}

export default ContentfulWalkThrough
