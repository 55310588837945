import * as React from "react"
import { getHtml, getImage } from "../utils"
import {
  ContentfulSelfServicePageContent,
  ContentfulSelfServicePageSection,
} from "../types"
import FeatureGrid, { FeatureGridProps } from "../components/FeatureGrid"

function ContentfulFeatureGrid(section: ContentfulSelfServicePageSection) {
  const props = mapProps(section)

  return <FeatureGrid {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): FeatureGridProps {
  // Pull the Content, Items, and Appearance blocks out of the Section block that is passed in
  const { content, items: itemList } = entry

  // Normalize the data
  const featureGridContent = content || ({} as ContentfulSelfServicePageContent)
  const items =
    itemList?.map((item: ContentfulSelfServicePageContent) => {
      return {
        heading: item.primaryText,
        text: getHtml(item.description),
        icon: item.images?.length ? null : getImage(item.icon),
        image: item.images?.length ? item.images[0].gatsbyImage : null,
      }
    }) ?? []

  // Return the props that will be passed to FeatureGrid
  return {
    heading: featureGridContent.primaryText,
    lede: getHtml(featureGridContent.description),
    items,
  }
}

export default ContentfulFeatureGrid
