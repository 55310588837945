import * as React from "react"
import { Heading, Text } from "gatsby-interface"
import {
  sectionCss,
  heroLedeCss,
  maxSectionWidth,
  sectionTitleCss,
} from "../style-utils"
import { getSSLPSanitizedHtml } from "../utils"
import { ColorSchemeCss } from "../color-schemes"
import { AnchorSection } from "./AnchorSection"

export interface RichTextBlockProps {
  text?: string
  title?: string
}

const containerCss: ColorSchemeCss = theme => [
  sectionCss(theme),
  {
    width: `100%`,
    boxSizing: `border-box`,
    padding: theme.space[8],
    [theme.mediaQueries.tablet]: {
      padding: `${theme.space[12]} ${theme.space[14]}`,
    },
    [theme.mediaQueries.desktop]: {
      padding: `0 ${theme.space[15]}`,
      marginLeft: `auto`,
      marginRight: `auto`,
    },
  },
  !theme.colorScheme.background && {
    // when using the default background color, use these styles
    maxWidth: maxSectionWidth.tablet, // inner container needs to be defined
    borderRadius: theme.radii[4], // container needs radius
    // "& *": { color: theme.colorScheme.text }, // container elements all have text color white (or defined default text color)
  },
]

const titleCss: ColorSchemeCss = theme => [
  sectionTitleCss(theme),
  {
    color: theme.colorScheme.primary,
    marginBottom: theme.space[8],
  },
]

function RichTextBlock({ text, title }: RichTextBlockProps) {
  return (
    <AnchorSection css={containerCss} id={title}>
      {title ? (
        <Heading as="h2" css={titleCss}>
          {title}
        </Heading>
      ) : null}
      {text ? (
        <Text
          as="div"
          css={theme => [heroLedeCss(theme)]}
          dangerouslySetInnerHTML={{
            __html: getSSLPSanitizedHtml(text),
          }}
        />
      ) : null}
    </AnchorSection>
  )
}

export default RichTextBlock
