import * as React from "react"
import Testimonial, { TestimonialProps } from "../components/Testimonial"
import { ContentfulSelfServicePageSection } from "../types"
import { getHtml, getImage } from "../utils"

const ContentfulTestimonial = (section: ContentfulSelfServicePageSection) => {
  const props = mapProps(section)
  return <Testimonial {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): TestimonialProps {
  const { content, appearance } = entry
  // Normalize the data
  const testimonialContent = content

  const icon = getImage(testimonialContent?.icon)
  const backgroundImage = appearance?.backgroundImages?.[0].gatsbyImage
  const avatarImage = testimonialContent?.images?.[0]?.resize?.src ?? ``

  return {
    text: getHtml(testimonialContent?.description),
    person: {
      avatar: avatarImage,
      name: testimonialContent?.primaryText,
      title: testimonialContent?.secondaryText || "",
    },
    icon,
    backgroundImage,
    // temporary workaround to style the 'empty' variant of component
    isInverted: appearance.theme === `GATSBY_INVERTED`,
  }
}

export default ContentfulTestimonial
