import * as React from "react"
import { Tabs, Tab, TabList, TabPanels, TabPanel } from "@reach/tabs"
import { AnchorSection } from "./AnchorSection"
import { ContentfulSelfServicePageSection } from "../types"
import { getSSLPSanitizedHtml } from "../utils"

function Root(props: any) {
  return (
    <AnchorSection {...props}>
      <div
        css={theme => ({
          paddingTop: theme.space[10],
          paddingBottom: theme.space[10],
          [theme.mediaQueries.phablet]: {
            paddingTop: theme.space[15],
            paddingBottom: theme.space[15],
          },
        })}
      >
        {props.children}
      </div>
    </AnchorSection>
  )
}

export default function TestimonialCarousel({
  content,
  items,
  ...props
}: ContentfulSelfServicePageSection) {
  return (
    <Root>
      <div>
        {content?.primaryText && (
          <h2
            dangerouslySetInnerHTML={{
              __html: getSSLPSanitizedHtml(content.primaryText),
            }}
            css={theme => ({
              textAlign: "center",
              fontWeight: theme.fontWeights.semiBold,
              marginTop: 0,
              marginBottom: theme.space[15],
              "& > b": {
                fontWeight: theme.fontWeights.semiBold,
                color: theme.colors.purple[60],
              },
            })}
          />
        )}
      </div>
      <Tabs>
        <TabList
          css={theme => ({
            display: "flex",
            gap: theme.space[5],
            alignItems: "center",
            justifyContent: "center",
            marginBottom: theme.space[5],
          })}
        >
          {items.map((item, i) => (
            <Tab
              key={item.contentful_id + i}
              css={theme => ({
                appearance: "none",
                backgroundImage: "none",
                color: "inherit",
                border: 0,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
                borderRadius: 8,
                backgroundColor: "transparent",
                cursor: "pointer",
                "&[aria-selected=true]": {
                  backgroundColor: theme.colors.purple[10],
                },
              })}
            >
              <img
                alt="Logo"
                src={item.icon.file.url}
                height="24"
                css={{
                  display: "block",
                  margin: 0,
                }}
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels
          css={theme => ({
            padding: theme.space[12],
            maxWidth: 1152,
            marginLeft: "auto",
            marginRight: "auto",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.colors.grey[20],
            borderRadius: theme.radii[4],
          })}
        >
          {items.map((item, i) => (
            <TabPanel key={item.contentful_id + i} css={theme => ({})}>
              <img alt="Logo" src={item.icon.file.url} height="36" />
              <blockquote
                dangerouslySetInnerHTML={{
                  __html: getSSLPSanitizedHtml(
                    item.description.childMarkdownRemark.html
                  ),
                }}
                css={theme => ({
                  fontSize: theme.fontSizes[3],
                  fontFamily: theme.fonts.monospace,
                  lineHeight: 1.75,
                  padding: 0,
                  margin: 0,
                  [theme.mediaQueries.tablet]: {
                    fontSize: theme.fontSizes[4],
                  },
                })}
              />
              <br />
              <cite
                css={theme => ({
                  fontStyle: "normal",
                })}
              >
                <div
                  css={theme => ({
                    fontWeight: theme.fontWeights.bold,
                    fontSize: theme.fontSizes[3],
                  })}
                >
                  {item.primaryText}
                </div>
                <div
                  css={theme => ({
                    fontSize: theme.fontSizes[1],
                  })}
                >
                  {item.secondaryText}
                </div>
              </cite>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Root>
  )
}
