import * as React from "react"
import { Heading, ThemeCss } from "gatsby-interface"
import { Buttons, Cta } from "./Ctas"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import {
  sectionCss,
  eyebrowHeadingCss,
  heroTitleCss,
  heroLedeCss,
  iconEyebrowHeadingCss,
  maxSectionWidth,
  gap,
} from "../style-utils"
import { ColorSchemeCss } from "../color-schemes"
import { AnchorSection } from "./AnchorSection"
import { getSSLPSanitizedHtml, alignmentStyles } from "../utils"
import { Alignment } from "../types"

export interface TwoColumnHeroProps {
  title: string
  eyebrow?: string
  lede?: string
  heroImage?: IGatsbyImageData
  primaryCta?: Cta
  secondaryCta?: Cta
  alignment?: Alignment
}

const headerCss: ThemeCss = theme => [
  {
    display: `flex`,
    flexDirection: `column`,
    position: "relative",
    gap: theme.space[gap.default],

    [theme.mediaQueries.desktop]: {
      flexDirection: "row",
      textAlign: "left",
      gap: theme.space[gap.desktop],
      alignItems: "center",
    },
  },
]

const mediaCss: ThemeCss = theme => ({
  width: "100%",
  maxWidth: "28rem",

  [theme.mediaQueries.desktop]: {
    maxWidth: "none",
    width: "50%",
  },
})

const contentCss: ColorSchemeCss = theme => ({
  color: theme.colorScheme.text,
  display: "grid",
  gap: theme.space[gap.default],
  maxWidth: maxSectionWidth.default,

  [theme.mediaQueries.desktop]: {
    maxWidth: "none",
    width: "50%",
    justifyItems: "flex-start",
  },
})

const titleCss: ColorSchemeCss = theme => [
  heroTitleCss(theme),
  {
    // reduce font-size from heroTitleCss when we switch to
    // the two-column layout
    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[9],
    },
  },
]

const ledeCss: ColorSchemeCss = theme => [
  heroLedeCss(theme),
  {
    whiteSpace: `pre-wrap`,
    color: theme.colorScheme.text,
    maxWidth: maxSectionWidth.default,
  },
]

function TwoColumnHero({
  title,
  eyebrow,
  lede,
  heroImage,
  primaryCta,
  secondaryCta,
}: TwoColumnHeroProps) {
  return (
    <AnchorSection
      css={theme => [
        sectionCss(theme),
        {
          paddingBottom: theme.space[12],
          paddingTop: theme.space[12],

          [theme.mediaQueries.phablet]: {
            paddingBottom: theme.space[14],
            paddingTop: theme.space[14],
          },

          [theme.mediaQueries.desktop]: {
            paddingBottom: theme.space[16],
            paddingTop: theme.space[16],
          },
        },
      ]}
      id={title}
    >
      <header
        css={theme => [
          // grab the default "center" alignmentStyles
          alignmentStyles(Alignment.Center),
          // overwrite the above to left-align for desktop and above
          // @TODO think about switching column order for `Alignment.Right`?!
          headerCss(theme),
        ]}
      >
        <div css={contentCss}>
          <div css={iconEyebrowHeadingCss}>
            {eyebrow ? (
              <Heading css={eyebrowHeadingCss} as="h2">
                {eyebrow}
              </Heading>
            ) : null}
            {title ? (
              <Heading
                as="h1"
                css={titleCss}
                dangerouslySetInnerHTML={{
                  __html: getSSLPSanitizedHtml(title),
                }}
              />
            ) : null}
          </div>
          {lede ? (
            <div
              css={ledeCss}
              dangerouslySetInnerHTML={{
                __html: getSSLPSanitizedHtml(lede),
              }}
            />
          ) : null}
          <Buttons primaryCta={primaryCta} secondaryCta={secondaryCta} />
        </div>
        <div css={mediaCss}>
          {heroImage ? <GatsbyImage image={heroImage} alt="" /> : null}
        </div>
      </header>
    </AnchorSection>
  )
}

export default TwoColumnHero
