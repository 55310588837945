import * as React from "react"
import { getHtml, getCtas } from "../utils"
import { Alignment, ContentfulSelfServicePageSection } from "../types"
import TwoColumnHero, { TwoColumnHeroProps } from "../components/TwoColumnHero"

const ContentfulTwoColumnHero = (section: ContentfulSelfServicePageSection) => {
  const props = mapProps(section)
  return <TwoColumnHero {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): TwoColumnHeroProps {
  // Pull the Content and Appearance blocks out of the Section block that is passed in
  const { content, alignment = Alignment.Center } = entry

  // Normalize the data
  const twoColumnHeroContent = content
  const heroImage = twoColumnHeroContent.images?.[0]
  const [primaryCta, secondaryCta] = getCtas(twoColumnHeroContent)

  // Return the props that will be passed to twoColumnHero
  return {
    title: twoColumnHeroContent?.primaryText,
    eyebrow: twoColumnHeroContent?.secondaryText,
    lede: getHtml(twoColumnHeroContent?.description),
    heroImage: heroImage?.constrained,
    primaryCta,
    secondaryCta,
    alignment,
  }
}

export default ContentfulTwoColumnHero
