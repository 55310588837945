import * as React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { FiCopy } from "react-icons/fi"

import { AnchorSection } from "./AnchorSection"
import { getSSLPSanitizedHtml } from "../utils"
import copyToClipboard from "../../../utils/copy-to-clipboard"

const components = {
  p: props => (
    <p
      {...props}
      css={theme => ({
        fontSize: theme.fontSizes[3],
      })}
    />
  ),
  pre: props => {
    // mdx renders pre > code with string children
    const text = props.children?.props?.children

    return (
      <div>
        <div
          css={theme => ({
            display: "inline-flex",
            alignItems: "stretch",
            borderRadius: theme.radii[3],
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.colors.purple[30],
            marginBottom: theme.space[3],
            overflow: "hidden",
          })}
        >
          <pre
            css={theme => ({
              fontFamily: theme.fonts.monospace,
              fontWeight: theme.fontWeights.semiBold,
              fontSize: theme.fontSizes[4],
              paddingTop: theme.space[3],
              paddingBottom: theme.space[3],
              paddingLeft: theme.space[7],
              paddingRight: theme.space[7],
              margin: 0,
              color: theme.colors.purple[50],
              backgroundColor: "transparent",
              borderRadius: 0,
            })}
          >
            {text}
          </pre>
          <button
            onClick={e => {
              copyToClipboard(text)
            }}
            title="Copy to clipboard"
            css={theme => ({
              display: "block",
              fontSize: 24,
              paddingLeft: theme.space[5],
              paddingRight: theme.space[5],
              margin: 0,
              appearance: "none",
              border: 0,
              borderRadius: 0,
              backgroundImage: "none",
              color: theme.colors.purple[50],
              backgroundColor: theme.colors.purple[10],
              cursor: "pointer",
              "& > svg": {
                display: "block",
                margin: 0,
              },
              "&:hover, &:focus": {
                backgroundColor: theme.colors.purple[20],
              },
              "&:active": {
                backgroundColor: theme.colors.purple[30],
              },
            })}
          >
            <FiCopy />
          </button>
        </div>
      </div>
    )
  },
}

function Root(props: any) {
  return (
    <MDXProvider components={components}>
      <AnchorSection
        {...props}
        css={theme => ({
          paddingTop: theme.space[10],
          paddingBottom: theme.space[10],
          [theme.mediaQueries.phablet]: {
            paddingTop: theme.space[15],
            paddingBottom: theme.space[15],
          },
        })}
      >
        {props.children}
      </AnchorSection>
    </MDXProvider>
  )
}

export default function HeroMDX({
  alignment,
  appearance,
  content,
  ...props
}: any) {
  const heading = getSSLPSanitizedHtml(content.primaryText)
  const [image] = content.images || []

  return (
    <Root id={heading}>
      {heading && (
        <h1
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
          css={theme => ({
            fontSize: theme.fontSizes[9],
            textAlign: "center",
          })}
        />
      )}
      {content.description?.childMdx?.body && (
        <div
          css={theme => ({
            textAlign: "center",
            maxWidth: 640,
            marginLeft: "auto",
            marginRight: "auto",
          })}
        >
          <MDXRenderer>{content.description.childMdx.body}</MDXRenderer>
        </div>
      )}
      {image?.file?.url && (
        <img
          src={image.file.url}
          alt={heading}
          css={theme => ({
            display: "block",
            width: "100%",
            maxWidth: 768,
            height: "auto",
            marginTop: theme.space[7],
            marginLeft: "auto",
            marginRight: "auto",
          })}
        />
      )}
    </Root>
  )
}
