import * as React from "react"
import { Alignment, ContentfulSelfServicePageSection } from "../types"
import LogoList, { LogoListProps, LogoListImage } from "../components/LogoList"
import { getImage } from "../utils"

const ContentfulLogoList = (section: ContentfulSelfServicePageSection) => {
  const props = mapProps(section)
  return <LogoList {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): LogoListProps {
  // Pull the Content and Appearance blocks out of the Section block that is passed in
  const { content, alignment = Alignment.Center } = entry

  // Normalize the data
  const logoListContent = content
  const logos =
    logoListContent?.logos?.map(logo => {
      return {
        src: getImage(logo.image),
        name: logo?.name,
      } as LogoListImage
    }) || []

  // Return the props that will be passed to LogoList
  return {
    alignment,
    logos,
    title: logoListContent?.primaryText,
  }
}

export default ContentfulLogoList
