import * as React from "react"
import { Link } from "gatsby"
import isAbsoluteURL from "is-absolute-url"
import { AnchorSection } from "./AnchorSection"
import { ContentfulSelfServicePageSection } from "../types"
import * as styles from "../style-utils"
import { getSSLPSanitizedHtml } from "../utils"

function Root(props: any) {
  return (
    <AnchorSection css={styles.sectionCss} {...props}>
      <div
        css={theme => ({
          width: `100%`,
          display: `grid`,
          gridTemplateColumns: `1fr`,
          gap: theme.space[9],
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: styles.maxSectionWidth.small,
          color: theme.colorScheme.heading,
          [theme.mediaQueries.tablet]: {
            gridTemplateColumns: `1fr 1fr`,
            maxWidth: styles.maxSectionWidth.tablet,
          },
        })}
      >
        {props.children}
      </div>
    </AnchorSection>
  )
}

function Item(props: any) {
  const heading = getSSLPSanitizedHtml(props.primaryText)
  const description = getSSLPSanitizedHtml(
    props.description.childMarkdownRemark.html
  )

  return (
    <div
      css={theme => ({
        padding: theme.space[7],
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: theme.radii[3],
        borderColor: theme.colors.grey[20],
      })}
    >
      {props.icon && (
        <div
          css={theme => ({
            maxHeight: theme.space[13],
            marginBottom: theme.space[5],
          })}
        >
          <img
            src={props.icon.file.url}
            role="presentation"
            css={theme => ({
              margin: 0,
              maxHeight: theme.space[13],
            })}
          />
        </div>
      )}
      {heading && (
        <h3
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
          css={theme => ({
            fontSize: theme.fontSizes[3],
            fontWeight: theme.fontWeights.semiBold,
          })}
        />
      )}
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
      {!!props.ctas?.length && (
        <div
          css={theme => ({
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: theme.space[3],
            fontSize: theme.fontSizes[1],
          })}
        >
          {props.ctas.map((cta, i) => (
            <CTA key={cta.href + i} {...cta} />
          ))}
        </div>
      )}
    </div>
  )
}

function CTA(props: any) {
  const external = isAbsoluteURL(props.href)
  const Component = external ? "a" : Link
  const linkProps = external
    ? {
        href: props.href,
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {
        to: props.href,
      }

  return (
    <Component
      {...linkProps}
      css={theme => ({
        textDecoration: "none",
        fontWeight: theme.fontWeights.semiBold,
      })}
    >
      {props.anchorText}
    </Component>
  )
}

export default function FeatureGridCTA({
  alignment,
  appearance,
  content,
  items,
}: ContentfulSelfServicePageSection) {
  const id = content?.primaryText

  return (
    <Root id={id}>
      {items.map((item, i) => (
        <Item key={item.contentful_id + i} {...item} />
      ))}
    </Root>
  )
}
