import * as React from "react"
import { Heading, ThemeCss } from "gatsby-interface"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { ColorSchemeCss } from "../color-schemes"
import { FeatureItem, FeatureItemProps } from "./FeatureItem"
import { sectionCss, sectionTitleCss, maxSectionWidth } from "../style-utils"
import { getSSLPSanitizedHtml } from "../utils"
import { Alignment } from "../types"
import { AnchorSection } from "./AnchorSection"

export interface FeatureItemsProps {
  heading: string
  alignment?: Alignment
  items: FeatureItemProps[] & { images?: IGatsbyImageData[] }
}

const containerCss: ColorSchemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  position: `relative`,
  margin: `auto`,
  maxWidth: maxSectionWidth.default,
  gridGap: theme.space[10],
  zIndex: 0,

  [theme.mediaQueries.desktop]: {
    maxWidth: "none",
  },
})

const flexCss: ThemeCss = theme => ({
  width: `100%`,
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: "center",
  gap: theme.space[12],
  color: theme.colors.grey[90],

  [theme.mediaQueries.tablet]: {
    "& > *": {
      flex: `1 1 45%`,
      maxWidth: `20rem`,
    },
  },

  [theme.mediaQueries.desktop]: {
    "& > *": {
      flex: `0 1 26%`,
    },
  },
})

const gridCss: ThemeCss = theme => ({
  width: `100%`,
  display: `grid`,
  gap: theme.space[10],
  color: theme.colors.grey[90],

  [theme.mediaQueries.phablet]: {
    gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
  },

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
  },
})

function FeatureItems({
  heading,
  alignment = Alignment.Center,
  items = [],
}: FeatureItemsProps): JSX.Element {
  return (
    <AnchorSection css={sectionCss} id={heading}>
      <div css={containerCss}>
        {heading ? (
          <Heading
            as="h2"
            css={theme => [sectionTitleCss(theme), { textAlign: alignment }]}
            dangerouslySetInnerHTML={{
              __html: getSSLPSanitizedHtml(heading),
            }}
          />
        ) : null}
        <div css={alignment === Alignment.Left ? gridCss : flexCss}>
          {items.map((item: FeatureItemProps, idx: number) => (
            <FeatureItem key={idx} feature={item} breakTitle={true} />
          ))}
        </div>
      </div>
    </AnchorSection>
  )
}

export default FeatureItems
