import * as React from "react"
import { ContentfulSelfServicePageSection } from "../types"
import FeaturedSites, { FeaturedSitesProps } from "../components/FeaturedSites"

function ContentfulFeaturedSites(section: ContentfulSelfServicePageSection) {
  const props = mapProps(section)

  return <FeaturedSites {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): FeaturedSitesProps {
  // Pull the Items blocks out of the Section block that is passed in
  const { items } = entry
  const sites = items || []
  // Return the props that will be passed to FeaturedSites
  return {
    sites,
  }
}

export default ContentfulFeaturedSites
