import * as React from "react"
import { ContentfulSelfServicePageContent } from "../types"
import { ColorSchemeCss } from "../color-schemes"
import { sectionCss } from "../style-utils"
import { FeaturedSitesGrid } from "../../../templates/showcase/featured-sites"
import { AnchorSection } from "./AnchorSection"

export interface FeaturedSitesProps {
  sites: ContentfulSelfServicePageContent[]
}

const containerCss: ColorSchemeCss = theme => ({
  backgroundColor: theme.colors.white,
  paddingLeft: theme.space[7],
  paddingRight: theme.space[7],
  position: `relative`,
})

function FeaturedSites({ sites = [] }: FeaturedSitesProps): JSX.Element {
  return (
    <AnchorSection // cannot be inverted at this time
      css={theme => [
        sectionCss(theme),
        {
          overflowX: "hidden",
          background: theme.colors.white,
        },
      ]}
    >
      <div css={containerCss}>
        <FeaturedSitesGrid
          featured={sites}
          hideShowcaseLink={true}
          setFilterToFeatured={null}
          setFilters={null}
          useModals={false}
        />
      </div>
    </AnchorSection>
  )
}

export default FeaturedSites
