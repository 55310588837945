import * as React from "react"
import {
  ContentfulSelfServicePageSection,
  ContentfulSelfServicePageResourceList,
  GetHtmlProps,
} from "../types"
import { getHtml } from "../utils"

import ResourceGrid, { ResourceGridProps } from "../components/ResourceGrid"

export default function ContentfulResourceGrid(
  section: ContentfulSelfServicePageSection
) {
  const props = mapProps(section)
  return <ResourceGrid {...props} />
}
const mapProps = (
  entry: ContentfulSelfServicePageSection
): ResourceGridProps => {
  // Pull the Content, Items, and Appearance blocks out of the Section block that is passed in
  const { content } = entry

  // Normalize the data
  const resourceGridContent = (content ||
    {}) as ContentfulSelfServicePageResourceList

  const {
    heading = "",
    description = "",
    contentType = "General",
    persona = "All",
    limit,
  } = resourceGridContent

  // Return the props that will be passed to FeatureGrid
  return {
    heading,
    description: getHtml(description as GetHtmlProps),
    contentType,
    persona,
    limit: limit ? +limit : null,
  }
}
