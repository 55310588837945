import * as React from "react"
import { Text, Avatar, Spacer } from "gatsby-interface"
import { ColorSchemeCss } from "../color-schemes"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { maxSectionWidth, sectionCss } from "../style-utils"
import { getSSLPSanitizedHtml } from "../utils"
import { Testimonials } from "../../pricing/Testimonials"

export interface TestimonialProps {
  text?: string
  person: {
    avatar: string
    name: string
    title: string
  }
  icon?: string
  backgroundImage?: IGatsbyImageData
  isInverted?: boolean
}

const wrapperStyles: ColorSchemeCss = theme => ({
  backgroundColor: theme.colorScheme.background,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  textAlign: `center`,
  margin: `0 auto`,
  width: `100%`,
  maxWidth: maxSectionWidth.tablet,
  borderRadius: theme.radii[4],
  position: `relative`,
  boxSizing: `border-box`,
  padding: theme.space[8],
  isolation: `isolate`,

  [theme.mediaQueries.tablet]: {
    padding: `${theme.space[12]} ${theme.space[14]}`,
  },

  [theme.mediaQueries.desktop]: {
    padding: theme.space[15],
  },
})

const overlayStyles: ColorSchemeCss = theme => ({
  width: `100%`,
  height: `100%`,
  position: `absolute`,
  top: 0,
  left: 0,
  backgroundColor: theme.colorScheme.muted,
  opacity: `95%`,

  [theme.mediaQueries.desktop]: {
    borderRadius: theme.radii[4],
  },
})

const quoteStyles: ColorSchemeCss = theme => ({
  color: theme.colorScheme.heading,
  zIndex: 1,
  fontSize: theme.fontSizes[4],

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[5],
  },
})

const nameStyles: ColorSchemeCss = theme => ({
  margin: 0,
  color: theme.colorScheme.heading,
  fontWeight: theme.fontWeights.bold,
  zIndex: 1,
})

const titleStyles: ColorSchemeCss = theme => ({
  margin: 0,
  color: theme.colorScheme.text,
  whiteSpace: `pre-wrap`,
  zIndex: 1,
})

const backgroundImageStyles: ColorSchemeCss = theme => ({
  width: `100%`,
  height: `100%`,
  position: `absolute`,
  top: 0,
  left: 0,
  borderRadius: theme.radii[4],
})

const iconCss: ColorSchemeCss = theme => ({
  maxHeight: theme.space[13],
  zIndex: 1,
})

const avatarWrapperCss = {
  display: `flex`,
  justifyContent: `center`,
}

const avatarInfoCss: ColorSchemeCss = theme => ({
  textAlign: `left`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  maxWidth: `8rem`,

  [theme.mediaQueries.desktop]: {
    maxWidth: `none`,
  },
})

function Testimonial({
  text,
  person,
  icon,
  backgroundImage,
  isInverted = false,
}: TestimonialProps) {
  // temporary solution to render hardcoded Testimonials created for /pricing page
  const noContent = !text

  return (
    <section css={sectionCss}>
      <div
        css={theme => [
          wrapperStyles(theme),
          noContent && { maxWidth: `100rem` },
        ]}
      >
        {noContent ? (
          <Testimonials isInverted={isInverted} />
        ) : (
          <>
            {backgroundImage ? (
              <GatsbyImage
                alt=""
                css={backgroundImageStyles}
                image={backgroundImage}
              />
            ) : null}
            <div css={overlayStyles} />
            {icon ? (
              <>
                <img css={iconCss} src={icon} alt="" />
                <Spacer
                  responsiveSize={{ desktop: 10, mobile: 5 }}
                  size={5}
                />{" "}
              </>
            ) : null}
            {text ? (
              <>
                <blockquote css={quoteStyles}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getSSLPSanitizedHtml(text),
                    }}
                  />
                </blockquote>
                <Spacer responsiveSize={{ desktop: 10, mobile: 5 }} size={5} />
              </>
            ) : null}
            <div css={avatarWrapperCss}>
              {person.avatar ? (
                <Avatar
                  label={`${person.name}'s avatar`}
                  src={person.avatar}
                  size="XL"
                  css={{
                    zIndex: 1,
                  }}
                />
              ) : null}
              <Spacer direction="horizontal" size={5} />
              <div css={avatarInfoCss}>
                {person.name ? (
                  <Text size="L" css={nameStyles}>
                    {person.name}
                  </Text>
                ) : null}
                {person.title ? (
                  <Text size="S" css={titleStyles}>
                    {person.title}
                  </Text>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default Testimonial
