import * as React from "react"
import { getCtas, getHtml, getImage } from "../utils"
import {
  ContentfulSelfServicePageContent,
  ContentfulSelfServicePageSection,
  ContentfulSSLPImage,
} from "../types"
import Banner, { BannerProps } from "../components/Banner"

function ContentfulBanner(section: ContentfulSelfServicePageSection) {
  const props = mapProps(section)

  return <Banner {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): BannerProps {
  // Pull the Content and Appearance blocks out of the Section block that is passed in
  const { content, appearance, alignment } = entry

  // Normalize the data
  const bannerContent = content || ({} as ContentfulSelfServicePageContent)
  const bannerImage = bannerContent?.images?.[0] || ({} as ContentfulSSLPImage)
  const videoEmbedId = bannerContent?.videoEmbedId || ""
  const backgroundImages = appearance?.backgroundImages || []
  const [primaryCta, secondaryCta] = getCtas(bannerContent)
  const iconSrc = getImage(bannerContent.icon)
  const leftBackgroundSrc = getImage(backgroundImages[0])
  const rightBackgroundSrc = getImage(backgroundImages[1])

  // Pass these props to Banner
  return {
    iconSrc,
    rightBackgroundSrc,
    leftBackgroundSrc,
    bannerImage: bannerImage?.constrained,
    title: bannerContent?.primaryText,
    eyebrow: bannerContent?.secondaryText || "",
    description: getHtml(bannerContent?.description),
    primaryCta,
    secondaryCta,
    videoEmbedId,
    alignment,
  }
}

export default ContentfulBanner
