import * as React from "react"
import { Heading, Link, Text } from "gatsby-interface"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { itemTitleCss, linkCss, textLinearGradient } from "../style-utils"
import { ColorSchemeCss } from "../color-schemes"
import VideoEmbed from "./VideoEmbed"
import { Alignment, ContentfulCta } from "../types"
import { getSSLPSanitizedHtml } from "../utils"

export interface FeatureItemProps {
  icon?: string
  image?: IGatsbyImageData
  title: string
  emTitle?: string
  text?: string
  ctas?: ContentfulCta[]
  textAlign?: Alignment
  embedId?: string
}

const rootCss: ColorSchemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  gap: theme.space[3],
  height: `100%`,
  padding: theme.space[8],
  backgroundColor: theme.colorScheme.background,

  [theme.mediaQueries.phablet]: {
    padding: theme.space[0],
  },
})

const emTitleCss: ColorSchemeCss = theme => ({
  color: theme.colorScheme.accent,
})

const titleCss: ColorSchemeCss = theme => [
  itemTitleCss(theme),
  {
    marginBottom: theme.space[5],
  },
]

const imageCss: ColorSchemeCss = theme => ({
  display: "block",
  marginBottom: theme.space[5],
  marginRight: "auto",
})

const learnMoreCss: ColorSchemeCss = theme => ({
  fontWeight: theme.fontWeights.bold,
  marginTop: `auto`,
  color: theme.colorScheme.primary, // cannot have white text color since this item has a white background

  "&:hover, &:focus": {
    color: theme.colorScheme.hover,
  },
})

const itemCopyCss: ColorSchemeCss = theme => ({
  color: theme.colorScheme.text,
  fontSize: theme.fontSizes[2],
  // for dangerouslySetInnerHTML
  "em, a, strong": {
    color: theme.colorScheme.primary, // text color cannot be white

    ...textLinearGradient({
      direction: theme.colorScheme.gradient.direction || 90,
      startColor: theme.colorScheme.gradient.start,
      endColor: theme.colorScheme.gradient.stop,
    }),
  },

  a: linkCss(theme),
})

export function FeatureItem({
  feature,
  breakTitle = false,
  ...rest
}: {
  feature: FeatureItemProps
  breakTitle: boolean
}): JSX.Element {
  const {
    icon,
    image,
    title,
    emTitle,
    text,
    ctas,
    textAlign = Alignment.Left,
    embedId,
  } = feature

  return (
    <div
      css={theme => [
        rootCss(theme),
        {
          alignItems:
            textAlign === Alignment.Center ? Alignment.Center : "start",
        },
      ]}
      {...rest}
    >
      <div css={{ width: `100%` }}>
        {icon ? (
          <img
            src={icon}
            alt={`${title} ${emTitle} icon`}
            css={theme => [
              imageCss(theme),
              { marginLeft: textAlign === Alignment.Center ? "auto" : "none" },
            ]}
            role="presentation"
          />
        ) : null}
        {image ? (
          <GatsbyImage
            image={image}
            alt={`${title} ${emTitle} icon`}
            css={imageCss}
          />
        ) : null}
        {embedId ? (
          <VideoEmbed title={title} embedId={embedId} css={imageCss} />
        ) : null}
        {title || emTitle ? (
          <Heading css={theme => [titleCss(theme), { textAlign }]} as="h3">
            {title} {breakTitle && <br />}{" "}
            <span css={emTitleCss}>{emTitle}</span>
          </Heading>
        ) : null}
      </div>
      {text ? (
        <Text
          as="div"
          css={theme => [itemCopyCss(theme), { textAlign }]}
          dangerouslySetInnerHTML={{
            __html: getSSLPSanitizedHtml(text),
          }}
        />
      ) : null}

      {!!ctas?.length &&
        ctas.map((cta, i) => (
          <Link key={cta.href + i} to={cta.href} css={learnMoreCss}>
            {cta.anchorText || "Learn More"}
          </Link>
        ))}
    </div>
  )
}
