import * as React from "react"
import { getHtml } from "../utils"
import {
  ContentfulSelfServicePageContent,
  ContentfulSelfServicePageSection,
  ContentfulSSLPForm,
} from "../types"
import ContactForm, { ContactFormProps } from "../components/ContactForm"

function ContentfulContactForm(section: ContentfulSelfServicePageSection) {
  const props = mapProps(section)

  return <ContactForm {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): ContactFormProps {
  // Pull the Content and Appearance blocks out of the Section block that is passed in
  const { content } = entry

  // Normalize the data
  // TODO: fallback and report error if formData is not provided
  const contactFormContent = content || ({} as ContentfulSelfServicePageContent)
  // Return the props that will be passed into ContactForm
  return {
    heading: contactFormContent?.primaryText,
    eyebrow: contactFormContent?.secondaryText || "",
    lede: getHtml(contactFormContent?.description),
    form: contactFormContent?.form || ({} as ContentfulSSLPForm),
  }
}

export default ContentfulContactForm
