import { IBMLogo } from "./logos/IBM"
import { JaxxonLogo } from "./logos/Jaxxon"
import { LittleCaesarsLogo } from "./logos/LittleCaesars"
import { BusinessComLogo } from "./logos/BusinessCom"
import { ClearlinkLogo } from "./logos/Clearlink"
import { MoneygeekLogo } from "./logos/Moneygeek"
import { DanielWellingtonLogo } from "./logos/DanielWellington"

export const testimonials = [
  {
    logo: LittleCaesarsLogo,
    quote: `Traffic spiked immediately after our Super Bowl ad aired, and we saw a high number of simultaneous uses. I remember watching and seeing we had 3,000 simultaneous users, then 10,000, then a few seconds later it was 15,000 — and we didn’t have to worry. Because we built with Gatsby, we knew we had the performance to handle the traffic without even thinking about it!`,
    name: `Andrew Smith`,
    position: `Application Architect`,
    company: `Little Caesars`,
    caseStudyLink: `/blog/2020-07-15-little-caesars-delivers-with-gatsby/`,
  },
  {
    logo: BusinessComLogo,
    quote: `We were so impressed with the developer experience with Gatsby, that the team pushed to move Business.com and Business News Daily over to Gatsby in order to improve our SEO and conversion. Our Lighthouse scores went from “low teens” to scores in the mid-60s! It helped foster a cultural change at our company where performance became a cross-cutting department concern, rather than just an engineering concern.`,
    name: `Spencer Sanchez`,
    position: `Tech Lead`,
    company: `Business.com`,
  },
  {
    logo: IBMLogo,
    quote: `With non-technical content writers and designers needing the ability to add content quickly to the site, Gatsby’s second to none onboarding made it easy for everyone, regardless of skill level, to be successful. This achieved one of the most important goals for the website: the documentation and process was there for the next person so they wouldn’t struggle. A home-grown system goes down when the people who created it leave. Gatsby doesn’t allow for that — it grows with whoever is at the controls — then seamlessly passes on to the next person.`,
    name: `Alison Joseph`,
    position: `Front-end Developer`,
    company: `IBM`,
    caseStudyLink: `/blog/2018-12-17-ibm-case-study/`,
  },

  {
    logo: ClearlinkLogo,
    quote: `Gatsby came at a time that we really needed to improve the speed of our sites in an easy way. Gatsby solved that issue by giving us a way to use a common library like React, but be able to statically generate our content to improve our speed. With the release of Gatsby Cloud, we are able to easily integrate with our headless CMS, Contentful, and be able to provide a fast way for our content editors to piece together pages and see previews of what they are creating in seconds.`,
    name: `Taylor Dawson`,
    position: `Director of Product Management `,
    company: `Clearlink`,
  },
  {
    logo: JaxxonLogo,
    quote: `As soon as we launched Jaxxon’s website on Gatsby, we saw really dramatic growth. Conversion went up from around 1% to over 2% and the bounce rate went from 40 to 50% to around 12%. Jaxxon has more than tripled their revenue and has now launched a women’s brand, too. Using Gatsby has been a huge step forward for all of us!`,
    name: `Trevor Heath`,
    position: `Project Tech Lead`,
    company: `Novvum`,
  },
  {
    logo: MoneygeekLogo,
    quote: `I’m really glad we decided to go with Gatsby—it gives us a much stronger foundation than many of our competitors. The Gatsby Concierge service has been amazing at not only helping our team identify certain problems that we’ve been having, but also providing detailed, specific code to fit it. With their help, we’ve improved our Core Web Vitals scores drastically.`,
    name: `Doug Jones`,
    position: `CEO`,
    company: `Moneygeek`,
  },
  {
    logo: DanielWellingtonLogo,
    quote: `We chose Gatsby as a tool to help us build our large, internationalized eCommerce website and reach optimal performance at Daniel Wellington. By splitting our 30K-page website into smaller 1K-page websites, we decreased our build time from over 20 minutes to just 3 minutes!`,
    name: `Thibaut Remy`,
    position: `Front-end Engineer`,
    company: `Daniel Wellington`,
    caseStudyLink: `/blog/2019-01-28-building-a-large-ecommerce-website-with-gatsby-at-daniel-wellington/`,
  },
]
