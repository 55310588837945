import * as React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Heading, Text, ThemeCss } from "gatsby-interface"
import VideoEmbed from "./VideoEmbed"
import { Buttons, Cta } from "./Ctas"
import { getSSLPSanitizedHtml } from "../utils"
import { ColorSchemeCss } from "../color-schemes"
import { AnchorSection } from "./AnchorSection"
import {
  sectionCss,
  eyebrowHeadingCss,
  sectionTitleCss,
  maxSectionWidth,
  iconEyebrowHeadingCss,
  alignmentCss,
} from "../style-utils"
import { Alignment } from "../types"

export interface BannerProps {
  title: string
  eyebrow: string
  description?: string
  iconSrc?: string
  videoEmbedId?: string
  bannerImage?: IGatsbyImageData
  alignment?: Alignment
  leftBackgroundSrc?: string
  rightBackgroundSrc?: string
  primaryCta: Cta
  secondaryCta: Cta
}

const containerCss: ThemeCss = () => ({
  position: "relative",
})

const wrapperCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[7],
  position: `relative`,
  zIndex: 2,

  [theme.mediaQueries.desktop]: {
    width: `52rem`,
  },
})

const titleCss: ColorSchemeCss = theme => [
  sectionTitleCss(theme),
  {
    span: {
      display: `block`,
    },
  },
]

const contentCss: ColorSchemeCss = theme => ({
  color: theme.colorScheme.text,
  margin: 0,
  maxWidth: maxSectionWidth.default,
  zIndex: theme.zIndices.base,
  whiteSpace: `pre-wrap`,
  p: {
    margin: 0,
  },
  "& *": {
    // for dangerouslySetInnerHTML
    // allows for white text when using inverted background - uses default when not inverted
    color: theme.colorScheme.text,
  },
})

const iconCss: ThemeCss = theme => ({
  maxHeight: theme.space[13], // 72px
})

const leftImageCss: ThemeCss = _ => ({
  position: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
})

const rightImageCss: ThemeCss = _ => ({
  position: "absolute",
  right: 0,
  top: 0,
  bottom: 0,
})

export function Banner({
  title,
  eyebrow,
  iconSrc,
  bannerImage,
  alignment = Alignment.Center,
  rightBackgroundSrc,
  leftBackgroundSrc,
  description,
  primaryCta,
  secondaryCta,
  videoEmbedId,
}: BannerProps) {
  return (
    <AnchorSection css={sectionCss} id={title}>
      <div css={containerCss}>
        {leftBackgroundSrc ? (
          <img
            src={leftBackgroundSrc}
            aria-hidden={true}
            alt=""
            css={leftImageCss}
          />
        ) : null}
        {rightBackgroundSrc ? (
          <img
            src={rightBackgroundSrc}
            aria-hidden={true}
            alt=""
            css={rightImageCss}
          />
        ) : null}
        <div css={theme => [wrapperCss(theme), alignmentCss(alignment)]}>
          <div css={iconEyebrowHeadingCss}>
            {iconSrc ? (
              <div css={theme => [iconCss(theme), alignmentCss(alignment)]}>
                <img src={iconSrc} aria-hidden={true} alt="" />
              </div>
            ) : null}
            {eyebrow ? <Text css={eyebrowHeadingCss}>{eyebrow}</Text> : null}
            {title ? (
              <Heading
                as="h2"
                css={titleCss}
                dangerouslySetInnerHTML={{
                  __html: getSSLPSanitizedHtml(title),
                }}
              />
            ) : null}
          </div>
          {bannerImage ? (
            <div css={_theme => alignmentCss(alignment)}>
              <GatsbyImage image={bannerImage} alt="" />
            </div>
          ) : null}
          {description ? (
            <Text
              as="div"
              size="L"
              css={theme => [contentCss(theme), alignmentCss(alignment)]}
              dangerouslySetInnerHTML={{
                __html: getSSLPSanitizedHtml(description),
              }}
            />
          ) : null}
          {videoEmbedId ? (
            <VideoEmbed title={title} embedId={videoEmbedId} />
          ) : null}
          <Buttons
            primaryCta={primaryCta}
            secondaryCta={secondaryCta}
            alignment={alignment}
          />
        </div>
      </div>
    </AnchorSection>
  )
}

export default Banner
