import * as React from "react"

export function JaxxonLogo() {
  return (
    <svg
      width="94"
      height="16"
      viewBox="0 0 94 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <title>Jaxxon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.59231 14.5631C9.462 13.6826 9.89684 12.4506 9.89684 10.8672V0.212402H6.96166V10.8006C6.96166 11.5257 6.7841 12.0843 6.42898 12.4765C6.07386 12.8687 5.57741 13.0647 4.93964 13.0647C4.43233 13.0647 3.8924 12.9131 3.31986 12.6097C2.74732 12.3063 2.20739 11.8809 1.70007 11.3333L0.330322 13.5531C1.57687 15.1069 3.1423 15.8838 5.02661 15.8838C6.53406 15.8838 7.72263 15.4436 8.59231 14.5631Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.365 0.212402L34.4961 7.78174L29.0171 15.7506H32.4306L36.2789 10.2013L40.1708 15.7506H43.6712L38.127 7.64855L43.2364 0.212402H39.7794L36.2789 5.31782L32.7785 0.212402H29.365Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.1624 0.212402V10.9338L83.3135 0.212402H80.5522V15.7506H83.4005V5.05145L91.2276 15.7506H93.9889V0.212402H91.1624Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7685 15.7506L23.4204 12.4654H15.9847L14.6367 15.7506H11.6145L18.2893 0.212402H21.3115L27.8993 15.7506H24.7685ZM17.0283 9.91269H22.3768L19.7243 3.38664L17.0283 9.91269Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.7668 1.05598C75.0061 1.7515 75.9809 2.70599 76.6911 3.91945C77.4013 5.13291 77.7565 6.47956 77.7565 7.95939C77.7565 9.43922 77.4013 10.7896 76.6911 12.0104C75.9809 13.2313 75.0061 14.1969 73.7668 14.9072C72.5275 15.6175 71.1541 15.9727 69.6467 15.9727C68.1392 15.9727 66.7658 15.6175 65.5265 14.9072C64.2872 14.1969 63.3125 13.2313 62.6022 12.0104C61.892 10.7896 61.5369 9.43922 61.5369 7.95939C61.5369 6.47956 61.892 5.13291 62.6022 3.91945C63.3125 2.70599 64.2872 1.7515 65.5265 1.05598C66.7658 0.360456 68.1392 0.0126953 69.6467 0.0126953C71.1541 0.0126953 72.5275 0.360456 73.7668 1.05598ZM67.1028 3.37561C66.3056 3.84176 65.6751 4.47809 65.2113 5.28459C64.7474 6.0911 64.5155 6.9827 64.5155 7.95939C64.5155 8.93608 64.7511 9.83137 65.2221 10.6453C65.6932 11.4592 66.3237 12.1029 67.1137 12.5765C67.9037 13.05 68.7625 13.2868 69.6901 13.2868C70.6033 13.2868 71.4513 13.05 72.234 12.5765C73.0167 12.1029 73.6363 11.4592 74.0929 10.6453C74.5495 9.83137 74.7778 8.93608 74.7778 7.95939C74.7778 6.9827 74.5495 6.0911 74.0929 5.28459C73.6363 4.47809 73.0167 3.84176 72.234 3.37561C71.4513 2.90946 70.6033 2.67639 69.6901 2.67639C68.7625 2.67639 67.9 2.90946 67.1028 3.37561Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4722 0.210449H48.8857L59.7785 15.7487H56.278L45.4722 0.210449ZM48.13 11.967L50.7879 15.75H45.4722L48.13 11.967ZM57.1204 3.9934L54.4625 0.210449H59.7783L57.1204 3.9934Z"
        fill="currentColor"
      />
    </svg>
  )
}
