import * as React from "react"
import { Heading, Text, Theme } from "gatsby-interface"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Interpolation } from "@emotion/serialize"
import { getSSLPSanitizedHtml } from "../utils"
import { Buttons, Cta } from "./Ctas"
import {
  sectionCss,
  eyebrowHeadingCss,
  sectionTitleCss,
  maxSectionWidth,
  gap,
  iconEyebrowHeadingCss,
} from "../style-utils"
import { ColorSchemeCss } from "../color-schemes"
import { AnchorSection } from "./AnchorSection"
import { Alignment } from "../types"

export interface FeatureColumnProps {
  title: string
  eyebrow: string
  description?: string
  image?: IGatsbyImageData | string
  icon?: string
  alignment: Alignment
  primaryCta?: Cta
  secondaryCta?: Cta
}

const containerCss = (theme: Theme, alignment: Alignment): Interpolation => ({
  display: `grid`,
  maxWidth: maxSectionWidth.default,
  margin: `auto`,
  alignItems: `center`,
  gridGap: theme.space[gap.default],
  gridTemplate: `"content"
                 "image"`,

  [theme.mediaQueries.desktop]: {
    gridGap: theme.space[gap.desktop],
    gridTemplate:
      alignment === Alignment.Right
        ? `"image content" / 2fr 1.675fr`
        : `"content image" / 1.675fr 2fr`,
    maxWidth: "none",
  },
})

const imageColumnCss: ColorSchemeCss = _ => ({
  gridArea: `image`,
})

const contentColumnCss = (
  theme: Theme,
  alignment: Alignment
): Interpolation => ({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  textAlign: `center`,
  gridArea: `content`,
  gridGap: theme.space[gap.default],

  [theme.mediaQueries.desktop]: {
    alignItems: `flex-start`,
    textAlign: `left`,
    order: 2,

    padding:
      alignment === Alignment.Right
        ? `0 0 0 ${theme.space[gap.super]}`
        : `0 ${theme.space[gap.super]} 0 0`,
  },
})

const iconCss: ColorSchemeCss = theme => ({
  maxHeight: theme.space[13], // 72px
  margin: 0,
  marginLeft: "auto",
  marginRight: "auto",

  [theme.mediaQueries.desktop]: {
    marginLeft: 0,
  },
})

const titleCss: ColorSchemeCss = theme => [
  sectionTitleCss(theme),
  {
    fontSize: theme.fontSizes[5],

    [theme.mediaQueries.phablet]: {
      fontSize: theme.fontSizes[7],
    },
  },
]

const descriptionCss: ColorSchemeCss = theme => ({
  margin: 0,
  color: theme.colorScheme.text,
  whiteSpace: `pre-wrap`,

  "& a": {
    color: theme.colorScheme.primary,
    fontWeight: theme.fontWeights.bold,
    textDecoration: `none`,
    borderBottom: `1px solid ${theme.colorScheme.accent}`,
  },
})

function FeatureColumn({
  title,
  eyebrow,
  description,
  image,
  icon,
  alignment,
  primaryCta,
  secondaryCta,
}: FeatureColumnProps) {
  return (
    <AnchorSection css={sectionCss} id={title}>
      <div css={theme => containerCss(theme, alignment)}>
        <div css={imageColumnCss}>
          {image &&
            (typeof image === "string" ? (
              <img
                css={_theme => ({
                  width: `fit-content`,
                  maxWidth: `100%`,
                  margin: `0 auto`,
                })}
                src={image}
                alt={title}
              />
            ) : (
              <GatsbyImage image={image} alt={title} />
            ))}
        </div>
        <div css={theme => contentColumnCss(theme, alignment)}>
          <div css={iconEyebrowHeadingCss}>
            {icon ? <img src={icon} css={iconCss} alt="" /> : null}
            {eyebrow ? (
              <Heading as="h3" css={eyebrowHeadingCss}>
                {eyebrow}
              </Heading>
            ) : null}
            {title ? (
              <Heading
                as="h2"
                css={titleCss}
                dangerouslySetInnerHTML={{
                  __html: getSSLPSanitizedHtml(title),
                }}
              />
            ) : null}
          </div>
          {description ? (
            <Text
              size="M"
              as="div"
              css={descriptionCss}
              dangerouslySetInnerHTML={{
                __html: getSSLPSanitizedHtml(description),
              }}
            ></Text>
          ) : null}
          <Buttons primaryCta={primaryCta} secondaryCta={secondaryCta} />
        </div>
      </div>
    </AnchorSection>
  )
}

export default FeatureColumn
