import * as React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { AnchorSection } from "./AnchorSection"
import { ContentfulSelfServicePageSection } from "../types"

const components = {
  p: props => (
    <p
      css={theme => ({
        color: theme.colors.grey[50],
        margin: 0,
      })}
      {...props}
    />
  ),
}

function Root(props: any) {
  return (
    <MDXProvider components={components}>
      <AnchorSection {...props}>
        <div
          css={theme => ({
            display: "grid",
            maxWidth: 1220,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: theme.space[10],
            marginBottom: theme.space[10],
            gap: theme.space[5],
            gridTemplateColumns: "1fr",
            gridTemplateRows: "auto",
            gridTemplateAreas: `
              "area0"
              "area1"
              "area2"
              "area3"
              "area4"
              "area5"
            `,
            [theme.mediaQueries.tablet]: {
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "auto",
              gridTemplateAreas: `
                "area0 area1"
                "area2 area3"
                "area4 area5"
              `,
            },
            [theme.mediaQueries.desktop]: {
              gridTemplateColumns: ".3fr .4fr .3fr",
              gridTemplateRows: "repeat(6, .3fr)",
              gridTemplateAreas: `
                "area0 area1 area2"
                "area0 area1 area2"
                "area0 area1 area3"
                "area4 area1 area3"
                "area4 area5 area5"
                "area4 area5 area5"
              `,
            },
          })}
        >
          {props.children}
        </div>
      </AnchorSection>
    </MDXProvider>
  )
}

function GridCell({ index, ...props }: any) {
  const [image] = props.images || []
  const [cta] = props.ctas || []

  // hard-coded per valhalla landing page
  // this _could_ be handled on the content side
  const horizontal = index === 5
  const primary = index === 1

  // only supports in-page & external links for now
  const Component = !!cta ? "a" : "div"
  const isAnchor = !!cta && cta?.href?.charAt(0) === "#"
  const rootProps = !!cta
    ? {
        href: cta.href,
        title: cta.anchorText,
        target: !isAnchor ? "_blank" : null,
        rel: !isAnchor ? "noopener noreferrer" : null,
      }
    : {}

  return (
    <Component
      {...rootProps}
      style={{
        gridArea: `area${index}`,
      }}
      css={theme => ({
        display: "flex",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.colors.grey[20],
        borderRadius: theme.radii[3],
        boxShadow: theme.shadows.raised, // floating, dialog, overlay
        backgroundColor: "white",
        overflow: "hidden",
        flexDirection: "column",
        textDecoration: "none",
        [theme.mediaQueries.desktop]: {
          flexDirection: horizontal ? "row" : "column",
        },
        "&:hover,&:focus": !!cta
          ? {
              boxShadow:
                "0px 2px 4px rgb(46 41 51 / 8%), 0px 4px 8px rgb(71 63 79 / 16%)",
              background: "#fcfaff",
              borderColor: "#d9bae8",
            }
          : null,
      })}
    >
      {props.icon && (
        <img
          src={props.icon.file.url}
          css={theme => ({
            display: "block",
            margin: "auto",
            marginBottom: "auto",
          })}
        />
      )}
      {image && (
        <img
          src={image.file.url}
          css={theme => ({
            objectFit: "contain",
            marginBottom: 0,
            [theme.mediaQueries.desktop]: {
              maxHeight: horizontal ? "100%" : "50%",
              maxWidth: horizontal ? "50%" : "100%",
              order: horizontal ? "2" : null,
            },
          })}
        />
      )}
      <div
        css={theme => ({
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "center",
          padding: 24,
          fontSize: primary ? theme.fontSizes[2] : theme.fontSizes[1],
          [theme.mediaQueries.desktop]: {
            textAlign: horizontal ? "left" : "center",
          },
        })}
      >
        <h3
          css={theme => ({
            fontSize: primary ? theme.fontSizes[4] : theme.fontSizes[3],
            fontWeight: theme.fontWeights.semiBold,
            margin: 0,
            marginBottom: 8,
          })}
        >
          {props.primaryText}
        </h3>
        <MDXRenderer>{props.description.childMdx.body}</MDXRenderer>
      </div>
    </Component>
  )
}

export default function BentoBox({
  items,
  ...props
}: ContentfulSelfServicePageSection) {
  return (
    <Root>
      {items.map((item, i) => (
        <GridCell key={i} index={i} {...item} />
      ))}
    </Root>
  )
}
