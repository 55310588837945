import * as React from "react"
import {
  ContentfulSelfServicePageContent,
  ContentfulSelfServicePageSection,
} from "../types"
import { getHtml } from "../utils"
import RichText, { RichTextBlockProps } from "../components/RichTextBlock"

const ContentfulRichText = (section: ContentfulSelfServicePageSection) => {
  const props = mapProps(section)
  return <RichText {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): RichTextBlockProps {
  const {
    content: richTextContent = {} as ContentfulSelfServicePageContent,
  } = entry
  const text = getHtml(richTextContent?.description)
  const title = richTextContent?.primaryText

  return {
    text,
    title,
  }
}

export default ContentfulRichText
