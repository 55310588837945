import * as React from "react"
import { Heading, Text, Link, Card } from "gatsby-interface"
import { getSSLPSanitizedHtml } from "../utils"
import {
  sectionCss,
  sectionTitleCss,
  maxSectionWidth,
  itemCss,
} from "../style-utils"
import { AnchorSection } from "./AnchorSection"
import { useStaticQuery, graphql } from "gatsby"
import { FeatureItem } from "./FeatureItem"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { ColorSchemeCss } from "../color-schemes"

export interface ResourceGridItem {
  metaTitle: string
  slug: string
  metaDescription: string
  contentType: string
  persona: string
  socialMediaImage: { gatsbyImage: IGatsbyImageData }
}

export interface ResourceGridProps {
  heading?: string
  description?: string
  contentType: string
  persona: string
  limit: number | null
}

const containerCss: ColorSchemeCss = theme => ({
  alignItems: `center`,
  display: `flex`,
  flexDirection: `column`,
  margin: `auto`,
  maxWidth: maxSectionWidth.default,
  gridGap: theme.space[7],

  [theme.mediaQueries.tablet]: {
    alignItems: `stretch`,
    maxWidth: maxSectionWidth.tablet,
  },
})

export default function ResourceGrid({
  heading,
  description,
  contentType,
}: ResourceGridProps): JSX.Element {
  const {
    allContentfulSelfServiceLandingPage: { nodes: pages },
  } = useStaticQuery(graphql`
    query {
      allContentfulSelfServiceLandingPage(
        filter: { contentType: { regex: "/./", ne: "General" } }
      ) {
        nodes {
          slug
          metaTitle
          metaDescription
          contentType
          persona
          socialMediaImage {
            gatsbyImage(layout: FIXED, width: 300)
          }
        }
      }
    }
  `)

  const resources = pages.filter(
    (page: ResourceGridItem) => page.contentType === contentType
  )

  return (
    <AnchorSection id={heading} css={sectionCss}>
      <div css={containerCss}>
        {heading ? (
          <Heading
            css={sectionTitleCss}
            dangerouslySetInnerHTML={{
              __html: getSSLPSanitizedHtml(heading),
            }}
          />
        ) : null}
        {description ? (
          <Text
            as="div"
            css={itemCss}
            dangerouslySetInnerHTML={{
              __html: getSSLPSanitizedHtml(description),
            }}
          />
        ) : null}
        <div
          css={theme => ({
            display: `grid`,
            gridTemplateColumns: `1fr`,
            gap: theme.space[11],
            justifyItems: `center`,
            [theme.mediaQueries.tablet]: {
              gridTemplateColumns: `1fr 1fr`,
            },
            [theme.mediaQueries.desktop]: {
              gridTemplateColumns: `1fr 1fr 1fr`,
              gap: theme.space[9],
            },
          })}
        >
          {resources.map((resource: ResourceGridItem) => (
            <Link
              key={resource.metaTitle}
              to={resource.slug}
              css={{
                alignItems: `stretch`,
                color: `inherit`,
                display: `flex`,
                maxWidth: `300px`,
                textDecoration: `none`,
                "&:hover, &:focus": {
                  textDecoration: `none`,
                },
              }}
            >
              <Card
                css={theme => ({
                  transition: `transform ${theme.transitions.speed.default}, box-shadow ${theme.transitions.speed.default}`,
                  "&:hover, &:focus": {
                    transform: `scale(1.05)`,
                    boxShadow: theme.shadows.floating,
                  },
                })}
              >
                <FeatureItem
                  breakTitle={false}
                  feature={{
                    title: resource.metaTitle,
                    text: resource.metaDescription,
                    link: resource.slug,
                    image: resource.socialMediaImage?.gatsbyImage,
                  }}
                />
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </AnchorSection>
  )
}
