import * as React from "react"
import { ColorSchemeCss } from "../color-schemes"
import { sectionCss } from "../style-utils"
import { Alignment } from "../types"
import { alignmentStyles } from "../utils"
import { AnchorSection } from "./AnchorSection"
import { Heading } from "gatsby-interface"
export interface LogoListProps {
  title: string
  alignment: Alignment
  logos: LogoListImage[]
}

export type LogoListImage = {
  src: string
  name: string
}

const titleCss: ColorSchemeCss = theme => ({
  color: theme.colorScheme.primary,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.body,
  letterSpacing: theme.letterSpacings.tight,
  maxWidth: "24ch",
  span: {
    color: theme.colorScheme.heading,
  },

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[3],
    fontWeight: theme.fontWeights.semiBold,
  },

  [theme.mediaQueries.hd]: {
    fontSize: theme.fontSizes[4],
    fontWeight: theme.fontWeights.body,
  },
})

const logosCss: ColorSchemeCss = theme => ({
  display: "flex",
  flexDirection: `column`,
  marginTop: theme.space[10],
  marginBottom: theme.space[10],
  width: `90%`,
  maxWidth: `1300px`,
  minWidth: `100px`,
  gap: theme.space[4],

  svg: {
    transform: `scale(0.75)`,
  },

  [theme.mediaQueries.tablet]: {
    flexDirection: `row`,
    alignItems: "center",
    gap: theme.space[10],
    svg: {
      transform: `scale(1)`,
    },
  },
})

const imageCss: ColorSchemeCss = theme => ({
  maxHeight: `64px`, // these values need to be determined - what is the largest icon we can have
  [theme.mediaQueries.tablet]: {
    maxWidth: `20%`, // these values need to be determined - what is the largest icon we can have
  },
})

function LogoList({ title, alignment, logos = [] }: LogoListProps) {
  if (logos.length === 0) return null // this component should be unusable without logos
  return (
    <AnchorSection id={title} css={sectionCss}>
      <div
        css={_theme => [
          { display: `flex`, flexDirection: `column` },
          alignmentStyles(alignment),
        ]}
      >
        {title ? (
          <Heading
            as="h2"
            css={theme => [titleCss(theme), alignmentStyles(alignment)]}
          >
            {title}
          </Heading>
        ) : null}
        <div css={theme => [logosCss(theme), alignmentStyles(alignment)]}>
          {logos.map(({ src, name }) => {
            return src ? (
              <img
                css={imageCss}
                src={src}
                alt={`an icon of ${name}`}
                role="presentation"
              />
            ) : null
          })}
        </div>
      </div>
    </AnchorSection>
  )
}

export default LogoList
