import { ThemeCss } from "gatsby-interface"
import * as React from "react"
import { sectionCss } from "../style-utils"
import { Cta } from "./Ctas"
import { Link } from "gatsby-interface"
import { AnchorSection } from "./AnchorSection"
import { ColorSchemeCss } from "../color-schemes"

export interface SubnavigationProps {
  ctas: Cta[]
}

const subnavCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  flexWrap: `wrap`,
  gap: theme.space[8],
  justifyContent: `space-evenly`,
  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,
  },
})

const linkCss: ColorSchemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  justifyContent: `center`,
  lineHeight: theme.lineHeights.dense,
  color: theme.colorScheme.text, // temporarily supported
  "&:hover, &:focus": {
    color: theme.colorScheme.text, // temporarily supported
  },
  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[3],
  },
})

function Subnavigation({ ctas }: SubnavigationProps): JSX.Element {
  return (
    <AnchorSection css={sectionCss}>
      <div css={subnavCss}>
        {ctas.map((cta: Cta) => {
          return (
            <Link key={cta.title} to={cta.to} variant="SIMPLE" css={linkCss}>
              {cta.title}
            </Link>
          )
        })}
      </div>
    </AnchorSection>
  )
}

export default Subnavigation
