import * as React from "react"
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs"
import { testimonials } from "./Testimonials.data"
import { colors, Heading, LinkButton, ThemeCss } from "gatsby-interface"
import { keyframes } from "@emotion/core"

export type TestimonialsProps = {
  isInverted: boolean
}

export function Testimonials({ isInverted }: TestimonialsProps) {
  return (
    <div>
      <Heading
        as="h3"
        css={theme => [
          headingCss(theme),
          isInverted && {
            color: theme.colors.grey[10],
          },
        ]}
      >
        Trusted by the world's top-performing website teams
      </Heading>

      <Tabs css={tabsCss}>
        <div css={tabListWrapperCss}>
          <TabList css={tabListCss}>
            {testimonials.map((item, idx) => {
              const Logo = item.logo

              return (
                <Tab
                  css={theme => [
                    tabCss(theme),
                    isInverted && {
                      color: theme.colors.grey[40],
                    },
                  ]}
                  key={idx}
                  index={idx}
                >
                  <Logo />
                </Tab>
              )
            })}
          </TabList>
        </div>
        <TabPanels>
          {testimonials.map((item, idx) => {
            const Logo = item.logo

            return (
              <TabPanel css={panelCss} key={idx} tabIndex="-1">
                <figure>
                  <Logo />
                  <blockquote>{item.quote}</blockquote>
                  <figcaption>
                    <strong>{item.name}</strong>
                    <span>{item.position}</span>
                    <span>{item.company}</span>
                    {item.caseStudyLink && (
                      <LinkButton to={item.caseStudyLink} css={linkCss}>
                        Read the case study
                      </LinkButton>
                    )}
                  </figcaption>
                </figure>
              </TabPanel>
            )
          })}
        </TabPanels>
      </Tabs>
    </div>
  )
}

/* styles */

const headingCss: ThemeCss = theme => ({
  textTransform: `uppercase`,
  fontWeight: theme.fontWeights.body,
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[60],
  marginBottom: theme.space[9],
  textAlign: `center`,
  letterSpacing: theme.letterSpacings.tracked,
  lineHeight: theme.lineHeights.loose,
})

const tabsCss: ThemeCss = _theme => ({
  display: `grid`,
})

const tabListWrapperCss: ThemeCss = theme => ({
  overflowX: `auto`,
  paddingBottom: theme.space[3],
})

const tabListCss: ThemeCss = theme => ({
  display: `inline-flex`,
  justifyContent: `space-around`,
  alignItems: `center`,

  [theme.mediaQueries.desktop]: {
    display: `flex`,
  },
})

const tabCss: ThemeCss = theme => ({
  border: 0,
  background: 0,
  color: theme.colors.grey[90],
  display: `flex`,
  cursor: `pointer`,
  margin: `${theme.space[3]} ${theme.space[5]}`,
  padding: theme.space[3],

  "&[data-selected]": {
    color: theme.colors.purple[50],
  },
})

const entry = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const panelCss: ThemeCss = theme => ({
  backgroundColor: theme.colors.purple[50],
  color: theme.colors.white,
  padding: `${theme.space[10]} 7% ${theme.space[9]}`,
  marginTop: theme.space[9],
  borderRadius: theme.radii[4],
  position: `relative`,
  tabIndex: -1,
  textAlign: `left`,

  svg: {
    transform: `scale(1.4)`,
    transformOrigin: `left bottom`,
    marginBottom: theme.space[8],
  },

  figure: {
    marginBottom: 0,
    animation: `${entry} 0.5s forwards`,
  },

  blockquote: {
    margin: 0,
    marginBottom: theme.space[10],
    fontSize: theme.fontSizes[3],
    lineHeight: theme.lineHeights.loose,
  },

  figcaption: {
    display: `grid`,

    strong: {
      fontSize: theme.fontSizes[3],
    },

    span: {
      opacity: 0.7,
    },
  },

  [theme.mediaQueries.desktop]: {
    padding: `${theme.space[13]} 7% ${theme.space[11]}`,
    marginLeft: `7%`,
    marginRight: `7%`,
  },
})

const linkCss: ThemeCss = theme => ({
  background: theme.colors.white,
  color: theme.colors.purple[50],
  marginTop: theme.space[5],

  "&:hover": {
    background: colors.purple[10],
  },

  [theme.mediaQueries.desktop]: {
    justifySelf: `end`,
    position: `absolute`,
    bottom: theme.space[8],
    right: theme.space[8],
  },
})
